<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title">
                <slot name="title">
                    <h3>{{ title }}</h3>
                </slot>
            </div>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
        <div class="card-footer" v-if="withFooter">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: ['title', 'withFooter'],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.card
    display: flex
    flex-direction: column
    position: relative
    border: none
    border-radius: $border-radius
    box-shadow: 0 0 3px 0 rgba(0,0,0,.5)
    .card-header
        padding: 15px
        border-bottom: 1px solid $gray-300-color
        .card-title
            h3
                margin: 0
    .card-body
        flex-grow: 1
        padding: 15px
    .card-footer
        padding: 15px
        text-align: center
        border-top: 1px solid $gray-300-color
    &.flex-grow-1
        flex-grow: 1
    &.title-text-center .card-header .card-title
        text-align: center
    &.no-body-padding-left .card-body
        padding-left: 0
</style>
