<template>
    <div class="btn-container" :class="{ 'btn-primary': primary, loading: isLoading, disabled: disabled }">
        <div class="info-container">
            <slot></slot>
        </div>
        <div class="title-container">
            <div>
                <div>
                    <template v-if="isLoading">
                        <span class="material-icons">{{ icon }}</span>
                    </template>
                    <template v-else>
                        <span class="material-icons">{{ icon }}</span>
                    </template>
                </div>
            </div>
        </div>
        <input type="button"/>
    </div>
</template>

<script>

export default {
    name: 'ActionBtnContainer',
    props: ['primary', 'icon', 'isLoading', 'disabled'],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.btn-container
    width: 28px !important
    height: 26px !important
    border: none
    border-radius: 0 !important
    box-shadow: 0 0 1px 0 $gray-400-color
    color: $gray-600-color
    span
        font-size: 16px
    &:hover:not(.disabled)
        border-color: $gray-400-color
    &.disabled
        span
            color: $gray-400-color
    &.loading span
        opacity: .5
        animation: rotate 2s linear infinite
    .info-container
        position: absolute
        top: -8px
        right: 1px
        z-index: 0

@keyframes rotate
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

</style>
