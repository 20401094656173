<template>
    <div class="text-container" :class="{ error: error }">
        <label>{{ label }}</label>
        <textarea :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
        <span v-if="error" class="error">{{ error }}</span>
    </div>
</template>

<script>

export default {
    name: 'InputContainer',
    props: ['modelValue', 'label', 'error'],
    data() {
        return {

        }
    },
    watch: {

    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

textarea
    height: inherit
</style>
