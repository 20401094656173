<template>
    <div class="input-container" :class="{ error: error }">
        <label>{{ label }}</label>
        <template v-if="isSecure">
            <input type="password" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
        </template>
        <template v-else>
            <input type="text" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
        </template>
        <span v-if="error" class="error">{{ error }}</span>
    </div>
</template>

<script>

export default {
    name: 'InputContainer',
    props: ['modelValue', 'label', 'error', 'is-secure'],
    data() {
        return {

        }
    },
    watch: {

    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'


</style>
