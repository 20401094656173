<template>
    <div class="slider-container">
        <label class="switch">
            <input type="checkbox" :checked="checked">
            <span class="slider round"></span>
        </label>
        <div class="title">{{ label }}</div>
    </div>
</template>

<script>

export default {
    name: 'SliderContainer',
    props: ['checked', 'label'],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.slider-container
    display: flex
    label
        margin-right: 4px

</style>
