import axios from 'axios';

const defineUser = () => axios.get('/admin/user/define');
const getUsers = () => axios.get('/admin/users');
const getAccessAreas = () => axios.get('/admin/access-area');

export default {
    defineUser,
    getUsers,
    getAccessAreas
}
