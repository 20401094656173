<template>
    <div class="nav-wrapper" v-if="items.length">
        <div class="nav-icon" @click="toggleNavigation">
            <template v-if="isNavigationCollapsed">
                <span class="material-icons-outlined">arrow_circle_left</span>
            </template>
            <template v-else>
                <span class="material-icons-outlined">arrow_circle_right</span>
            </template>
        </div>
        <div class="navigation" v-if="!isNavigationCollapsed">
            <h4>Навигация по сервисам</h4>
            <div class="nav-list">
                <template v-for="(item, index) in items" :key="index">
                    <div class="nav-bar" @click="navigateTo('/' + item.code)">
                        <!--<div class="icon">-->
                            <!--<span class="material-icons">smart_toy</span>-->
                        <!--</div>-->
                        <div class="title">
                            <div class="label">{{ item.alias }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Navigation',
    components: {

    },
    props: ['excluded'],
    data() {
        return {
            items: []
        }
    },
    computed: {
        ...mapGetters([
            'authUser',
            'accessAreas',
            'isNavigationCollapsed'
        ])
    },
    watch: {
        authUser() {
            this.calcItems();
        },
        accessAreas() {
            this.calcItems();
        }
    },
    methods: {
        navigateTo(to) {
            location.assign(to);
        },
        toggleNavigation() {
            this.$store.commit('setIsNavigationCollapsed', !this.isNavigationCollapsed);
        },
        calcItems() {
            if (!this.authUser) return;
            if (!this.accessAreas) return;
            this.items = this.accessAreas
                .filter(area => this.authUser.accessAreaIds.includes(area.id) && area.code !== this.excluded);
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../common/assets/index.sass'

.nav-wrapper
    position: fixed
    top: 0
    right: 0
    height: 100vh
    background-color: $gray-50-color
    box-shadow: 0 0 6px 0 $gray-400-color
    z-index: 100
    .nav-icon
        position: absolute
        top: 50%
        left: -33px
        display: flex
        flex-direction: column
        justify-content: center
        padding: 4px
        color: $gray-400-color
        border-top: 1px solid $gray-300-color
        border-bottom: 1px solid $gray-300-color
        border-left: 1px solid $gray-300-color
        background-color: $gray-50-color
        cursor: pointer
        &:hover
            color: $gray-700-color
    .navigation
        h4
            text-align: center
        .nav-list
            .nav-bar
                display: flex
                justify-content: flex-start
                padding: 4px 8px
                border-bottom: 1px solid $gray-300-color
                cursor: pointer
                &:hover
                    .icon
                        color: $brand-primary-color
                    background-color: $gray-300-color
                .title
                    display: flex
                    flex-direction: column
                    justify-content: center
                    margin-left: 4px
                .icon
                    color: $gray-700-color

</style>
