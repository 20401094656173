import api from '../api';

console.log(process.env);

export default {
    state: {
        authUser: null,
        users: [],
        accessAreas: [],
        tokenExpired: false,
        isNavigationCollapsed: true
    },
    mutations: {
        setAuthUser: ( state, user ) => state.authUser = user,
        setUsers: ( state, users ) => state.users = users,
        setAccessAreas: ( state, list ) => state.accessAreas = list,
        setTokenExpired: ( state, expired ) => state.tokenExpired = expired,
        setIsNavigationCollapsed: ( state, collapsed ) => state.isNavigationCollapsed = collapsed
    },
    getters: {
        authUser: state => state.authUser,
        users: state => state.users,
        accessAreas: state => state.accessAreas,
        tokenExpired: state => state.tokenExpired,
        isNavigationCollapsed: state => state.isNavigationCollapsed
    },
    actions: {
        getUsers({ commit }) {
            api.getUsers()
                .then(r => commit('setUsers', r.data.data))
                .catch(e => console.error(e));
        },
        getAccessAreas({ commit }) {
            api.getAccessAreas()
                .then(r => commit('setAccessAreas', r.data.data))
                .catch(e => console.error(e));
        },
        defineUser({ commit }) {
            api.defineUser()
                .then(r => commit('setAuthUser', r.data.data))
                .catch(e => {
                    console.error(e);
                    commit('setTokenExpired', true);
                });
        }
    }
}
