<template>
    <div class="btn-container" :class="{ 'btn-primary': primary, 'btn-small': small }">
        <div class="title-container">
            <div>
                <div>
                    <div>
                        <span class="title">{{ title }}</span>
                    </div>
                </div>
            </div>
        </div>
        <input type="button"/>
    </div>
</template>

<script>

export default {
    name: 'BtnContainer',
    props: ['primary', 'small', 'icon', 'title'],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.material-icons
    line-height: 1
</style>
